import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DateInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          const body = event.body;
          this.convertDatesToUTC(body);
        }
        return event;
      })
    );
  }

  private convertDatesToUTC(body: unknown) {
    if (body === null || body === undefined || typeof body !== 'object') return;

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isIso8601Date(value)) {
        body[key] = new Date(value); // Zakładamy, że 'value' jest w UTC
      } else if (typeof value === 'object') {
        this.convertDatesToUTC(value);
      }
    }
  }

  private isIso8601Date(value: unknown): boolean {
    return typeof value === 'string' &&
           /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:\d{2}|Z)?$/.test(value);
  }
}
